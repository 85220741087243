import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./indexPage.scss"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Shakespeare's Sonnets, limericked" />
    <div className="IndexPage">
      {data.allMarkdownRemark.edges
        .sort(
          (a, b) =>
            parseInt(a.node.frontmatter.title.split(" ")[1], 10) -
            parseInt(b.node.frontmatter.title.split(" ")[1], 10)
        )
        .map(edge => {
          const { frontmatter, html } = edge.node
          const { path, limericked, date } = frontmatter
          const limerickRegex = /(?:<div class="SonnetPage--limerick">)([\s\S]*)(?:<\/div>)/g
          const limerick = limerickRegex.exec(html)[1]
          const number = path.replace("/", "")
          return (
            <div key={path} className="IndexPage--limerick">
              <Link
                key={path}
                className={`IndexPage--panel${limericked ? "" : " --blank"}`}
                to={path}
              >
                {limericked && (
                  <>
                    <span className="IndexPage--number">{number}.</span>
                    {limerick.split("\n").map((line, i) => {
                      return (
                        <span className="IndexPage--line" key={i}
                          dangerouslySetInnerHTML={{ __html: line }}>
                        </span>
                      )
                    })}
                  </>
                )}
                {!limericked && (
                  <>
                    <span className="IndexPage--number">{number}.</span>
                    <span className="IndexPage--date">
                      to be limericked on {date}
                    </span>
                  </>
                )}
                <p className="IndexPage--original-hint">
                  click to read the original
                </p>
              </Link>
            </div>
          )
        })}
    </div>
  </Layout>
)

export const query = graphql`
  query IndexPageQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            limericked
            path
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

export default IndexPage
